const listeActionReducer = (state = [], action) => {

	switch (action.type) {
		case 'RECEIVE_ACTIONS':
			return action.payload
		default:
			return state
	}
}

export default listeActionReducer