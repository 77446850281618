const initialState = {
    email:{
        value:'',
        helperText:'',
        error:false,
    },
    password:{
        value:'',
        helperText:'',
        error:false,
    },
}

const login = (state = initialState, action) => {

    switch (action.type) {
        case 'CHANGE_FORM_LOGIN':
            let changeState = {...state}
            changeState[action.key] = {value:action.value,helperText:'',error:false}
            return changeState

        case 'ERROR_LOGIN':
            let newState = {...state}
            let field = newState[action.payload.field]
            field.error = true
            if(action.payload.field==='password'){
                field.value = ''
            }
            field.helperText = action.payload.message
            return newState

        default:
            return state

    }
}

export default login