import {combineReducers} from 'redux'
import taskFinishedReducer from '../Views/Dashboard/taskFinishedReducer'
import taskInProgressReducer from '../Views/Dashboard/taskInProgressReducer'
import taskPlannedReducer from '../Views/Dashboard/taskPlannedReducer'
import tasks from '../Views/Task/tasksReducer'
import taskDetailsReducer from "../Views/Task/taskDetailsReducer"
import user from "./user"
import login from "../auth/loginReducer"
import listAction from "../Views/Task/TaskDetails/listAction"
import detailsExecution from "./detailsExecution"
import listTaskActionReducer from "../Views/Task/listTaskActionReducer"
import launchJobReducer from "../Views/Task/launchJobReducer"
import listTaskLogsDetailsbyIdReducer from "../Views/Task/listTaskLogsDetailsbyIdReducer"
import statutActionReducer from "../Views/Task/statutActionReducer"
import listeActionReducer from "../Views/Action/listeActionReducer"
import actionsDetailsReducer from "../Views/Action/ActionDetails/actionsDetailsReducer"
import actionLogsReducer from "../Views/Action/ActionDetails/actionLogsReducer"

export default combineReducers({
	taskFinishedReducer,
	taskInProgressReducer,
    taskPlannedReducer,
    tasks,
	taskDetailsReducer,
	login,
	user,
	listAction,
	detailsExecution,
	listTaskActionReducer,
	listTaskLogsDetailsbyIdReducer,
	launchJobReducer,
	statutActionReducer,
	listeActionReducer,
	actionsDetailsReducer,
	actionLogsReducer,
})

