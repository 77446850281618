import React from 'react'
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import Title from './Title.js'
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			backgroundColor: theme.palette.background.paper,
			marginTop:'25px'
		},
		empty: {
			height: '150px',
			fontSize: '14px',
			fontFamily: 'Roboto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center'
		},
	}),
);


export default function DataList(props) {
	const classes = useStyles()
	const {children, title, emptyText, nbItems ,isPending} = props
	return (
        <div>
			<Title>{title}</Title>

            {isPending ? (
                <div className={classes.empty}>
                    <CircularProgress className={classes.progress} />
                </div>
            ) : (
                <div className={classes.root}>
                    {nbItems > 0 ? (
                        <List component="nav">
                            {children}
                        </List>
                    ) : (
                        <div className={classes.empty}>
                            {emptyText}
                        </div>
                    )}
                </div>
            )}

        </div>
	)
}