import React from 'react'
import {connect} from 'react-redux'
import ListItemLink from '../../components/ListItemLink'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import {loadTasks} from '../../actions/index'

class Task extends React.Component {

	componentDidMount() {
		this.props.loadTasks()
	}

	render() {
		const {taskId,tasks, withoutContainer} = this.props

		let taskGrid = (
			<Grid item xs={4}>
				<ListItemLink title="Tâches Recurentes" url="/Task/" items={tasks.scheduled_tasks} selectedId={taskId} />
				<Divider/>
				<ListItemLink title="Tâches Manuelles" url="/Task/" items={tasks.manual_tasks} selectedId={taskId}/>
			</Grid>
		)

		if (!withoutContainer) {
			taskGrid = (<Grid container spacing={0}>{taskGrid}</Grid>)
		}

		return taskGrid
	}
}

const mapStateToProps = state => ({
	tasks: state.tasks,

})

const mapDispatchToProps = dispatch => ({
	loadTasks: () => dispatch(loadTasks()),
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Task)




