const taskPlannedReducer = (state = {items:[],isPending:false}, action) => {
    switch (action.type) {
        case 'REQUEST_TASKS/SCHEDULED':
            return {items:[],isPending:true}
        case 'RECEIVE_TASKS/SCHEDULED':
            return {items:action.payload,isPending:false}
        default:
            return state
    }
}
export default taskPlannedReducer