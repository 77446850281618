import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import PopupButton from './PopupButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TimerIcon from '@material-ui/icons/Timer'
import {Link} from 'react-router-dom'
const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	button: {
		margin: theme.spacing(1),

	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
    selected:{
		backgroundColor:'blue'
	}
});

/**
 * return list Item  with or  without  link buttun
 * @param props classes {class} css classe , title {string} title of array  , url {string} , items {array} list élément
 * @returns {XML}
 * @constructor
 */
function ListItemLink(props) {
	const {classes, title, url, items, selectedId  } = props
	let listItems = ""

	if (!url) {
		listItems = items.map((item) =>
			 <ListItem key={item.id} button >
				<ListItemText inset primary={item.dateCreated}/>
				<ListItemIcon>
					<TimerIcon/>
				</ListItemIcon>
			</ListItem>
	)
		return (
			<div>
				<Typography variant="subtitle1" gutterBottom>
					{title}
				</Typography>
				<List component="nav"
				>
					<div className={classes.root}>
						{items.length > 0 ? (
							<List component="nav">
								{listItems}
							</List>
						) : (

							<div className={classes.empty}>
								C'est vide !
							</div>
						)}
					</div>
				</List>
			</div>
		)
	} else {
		listItems = items.map((item) =>{

			return <MenuItem  key={item.id} button component={Link} to={url + item.id} selected={selectedId === item.id.toString()} >
				<ListItemText primary={item.name}/>
			</MenuItem >
	}
		)
		return (
			<div>
				<Typography variant="subtitle1" gutterBottom>
					{title}
					<PopupButton title={title}/>
				</Typography>
				<List component="nav"
				>
					<div className={classes.root}>
						{items.length > 0 ? (
							<List component="nav">
								{listItems}
							</List>
						) : (

							<div className={classes.empty}>
                                Vide de chez vide !
							</div>
						)}
					</div>
				</List>
			</div>
		)
	}
}

ListItemLink.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListItemLink)
