import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	button: {
		margin: theme.spacing(1),

	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	}

})

class PopupButton extends React.Component {

	state = {
		open: false,
	}

	handleClickOpen = () => {
		this.setState({open: true});
	}

	handleClose = () => {
		this.setState({open: false});
	}

	render() {
		const {
			classes
		} = this.props
		return (
			<span>
				<Button aria-label="Add" onClick={this.handleClickOpen} className={classes.button}>
					<AddIcon/>
				</Button>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">Ajouter {this.props.title}</DialogTitle>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="nom"
							type="text"
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Annuler
						</Button>
						<Button onClick={this.handleClose} color="primary">
							Ajouter
						</Button>
					</DialogActions>
				</Dialog>
			</span>

		)
	}
}

PopupButton.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PopupButton)