const taskInprogressReducer = (state = {items:[],isPending:false}, action) => {
    switch (action.type) {
        case 'REQUEST_TASKLOGS/INPROGRESS':
            return {items:[],isPending:true}
        case 'RECEIVE_TASKLOGS/INPROGRESS':
            return {items:action.payload,isPending:false}
        default:
            return state
    }
}
export default taskInprogressReducer