import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {connect} from 'react-redux'
import {toggleAction} from '../../../actions/index'
const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},


})

function PopupCheckbox (props) {
	const {  actions, idsActions,toggleAction } = props
	const listGoup =  idsActions.map((idAction) =>

		<FormGroup row key={actions[idAction].id}>
			<FormControlLabel
				control={
					<Checkbox
						// checked={this.state.checkedCleaning}
						 onChange={(e)=>toggleAction(idAction)}
						checked={actions[idAction].checked}

					/>
				}
				label={actions[idAction].title}
			/>
		</FormGroup>
	)
	return (
		<div>
			{listGoup}
		</div>)
}
PopupCheckbox.propTypes = {
	classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({


})

const mapDispatchToProps = dispatch => ({
	toggleAction: (id) => dispatch(toggleAction(id)),

})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PopupCheckbox))