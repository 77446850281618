let defaultState = [
	{
		"id": 2,
		"state": "SUCCESS",
		"started_at": "2019-05-16 07:49:17",
		"log": "16-05-2019 07:49:17 - info: Batching sqls all 1000 lines\n16-05-2019 07:49:17 - info: File found TEST_FILE_20K.csv\n16-05-2019 07:49:18 - info: Start reading file\n16-05-2019 07:49:18 - info: File header name;value\n16-05-2019 07:49:18 - warning: row must have 2 columns on line 13 [value2]\n16-05-2019 07:49:18 - warning: row must have 2 columns on line 14 [105]\n16-05-2019 07:49:18 - info: End reading file\n16-05-2019 07:49:19 - warning: 2 lines ignored\n16-05-2019 07:49:19 - notice: 9998 lines imported\n16-05-2019 07:49:19 - info: File not moved TEST_FILE_20K.csv\n",
		"duration": 3,
		"task": {
			"id": 1,
			"name": "Test task"
		}
	}
]
const actionLogsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_ACTIONS_LOGS':
			return action.payload
		default:
			return state
	}
}
export default actionLogsReducer