
const defaultState = {
    cron:"",
    id:1,
    isOpen:false,
    is_scheduled:0,
    name:"Imports fichiers",
    nextExecution:"Today at 11:56 AM",
    actions: []
}

const taskDetailsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_TASK_DETAILS':
			return action.payload

		default:
			return state
	}
}
export default taskDetailsReducer