import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import {connect} from 'react-redux'
import {toggleSatutAction} from '../../../actions/index'
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	button: {
		margin: theme.spacing(1),

	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	listAction: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		marginBottom:'20px'
	},

})

/**
 * return  list  Item  with  component button  swicht or component empty
 * @param props {object} items , {string} checked
 * @returns {XML}
 * @constructor
 */
class ListItemSwicht extends React.Component {

	state = {
		toggleSwitch: false
	};

	handleChange = (id) => {
		this.props.toggleAction(id);
		this.props.refresh(this.props.idTask);
		this.setState({toggleSwitch: true}, () => this.forceUpdate())
		this.props.refresh(this.props.idTask);
	}

	handleClose = () => {
		this.setState({toggleSwitch: false});
	};


	render() {
		const {classes, items, statutAction} = this.props;

		const listItems = items.map((item) =>
			<ListItem key={item.id} button>
				<ListItemText primary={item.name}/>
				<ListItemSecondaryAction>
					<Switch
						color={'primary'}
						checked={!item.pivot.is_disabled}
						onChange={() => this.handleChange(item.pivot.id)}
					/>
				</ListItemSecondaryAction>
			</ListItem>
		)

		return (
			<div>
				<List component="nav" className={classes.listAction}>
					<div className={classes.root}>
						{items.length > 0 ? (
							<List component="nav">
								{listItems}
							</List>
						) : (

							<div className={classes.empty}>
								Aucune données
							</div>
						)}
					</div>
				</List>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.toggleSwitch}
					autoHideDuration={2000}
					onClose={this.handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id="message-id">{statutAction.message}</span>}
				/>
			</div>

		)
	}
}

ListItemSwicht.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	statutAction: state.statutActionReducer
})

const mapDispatchToProps = dispatch => ({
	toggleAction: (id) => dispatch(toggleSatutAction(id))

})

ListItemSwicht = withStyles(styles)(ListItemSwicht)

export default connect(mapStateToProps, mapDispatchToProps)(ListItemSwicht)
