import React from 'react'
import {Switch, BrowserRouter} from 'react-router-dom'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Login from "../auth/LoginContainer"
import Dashboard from "../Views/Dashboard/index"
import NotFound from "../components/NotFound"
import Action from "../Views/Action/index"
import ActionDetails from "../Views/Action/ActionDetails/ActionDetails"
import Task from "../Views/Task/index"
import TaskDetails from "../Views/Task/TaskDetails/TaskDetails"
const Router = () => (
	<BrowserRouter>
		<div>
			<Switch>
				<PrivateRoute path="/Notfoud" component={NotFound}/>
				<PrivateRoute path="/Task/:id" component={TaskDetails}/>
				<PrivateRoute path="/Task" component={Task}/>
				<PrivateRoute path="/Action/:id" component={ActionDetails}/>
				<PrivateRoute path="/Action" component={Action}/>
				<PublicRoute path="/login" component={Login}/>
				<PrivateRoute exactly path="/" component={Dashboard}/>
			</Switch>
		</div>
	</BrowserRouter>
)
export default Router
