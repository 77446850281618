let defaultState = {
	isOpen: false,
	data: {
		title: 'Import quotidien ',
		delaiExecution: 'hier a 6h',
		shedule: '6s',
		actions: [
			{id: 1, title: 'Article', shedule: '6s'},
			{id: 1, title: 'Vente', shedule: '4s'},
			{id: 1, title: 'Nomenclature', shedule: '10s'}
		]
	}

}

const DetailsExecution = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_DETAILSEXECUTION':
		return {
			...state,
			data:action.data
		}

		case 'TOGGLE_DETAILS_EXECUTION':
			return {...state,
				isOpen:!state.isOpen
			}

		default:
			return state
	}
}
export default DetailsExecution