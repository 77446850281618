import React from 'react'
//components
import  Router from './routes/Router'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware ,compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers/index'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers,composeEnhancers(
    applyMiddleware(thunk)
));

const App = () =>{
    return(
        <Provider store={store}>
            <Router/>
        </Provider>
    )
}

export default App