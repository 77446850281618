import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TimerIcon from '@material-ui/icons/Timer'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	error: {
		color: '#EB5757'
	},
	flexContainer: {
		listStyle: 'none',
		display: 'flex',
		justifyContent: 'stretch',
	},

	duration: {
		textAlign: 'left',
		marginLeft: '-86px'
	}
});

class ListeActionLogs extends React.Component {


	state = {
		openId: 0,
		name: '',
		action: ''
	};


	handleClickOpen = (actionLogId, log ,action) => {
		this.setState({openId: actionLogId, name: log ,action: action });
	};

	handleClose = () => {
		this.setState({openId: 0, name: ''});
	};

	render() {
		const {classes, title, items} = this.props
		const listItems = items.map((item) => {
				let itemClass = item.state === 'ERROR' ? {root: classes.error }: {}
				return <ListItem    key={item.id} button onClick={e => this.handleClickOpen(item.id, item.log,item.task.name )}>
					<ListItemText primary={item.task.name} classes={itemClass}/>
					<ListItemText primary={item.started_at} classes={itemClass} />
					<ListItemIcon classes={itemClass}  style={{textAlign: 'right'}}>
						<TimerIcon  />
					</ListItemIcon>
					<ListItemText inset primary={item.duration + "s"} classes={itemClass}
					              className={classes.duration}/>
				</ListItem>
			}
		)

		return (
			<div>
				<Dialog
					onClose={this.handleClose}
					open={this.state.openId === 0 ? false:true}
				>
					<DialogTitle id="alert-dialog-title">{this.state.action}</DialogTitle>
					<Divider/>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">

						    <span style={{whiteSpace: 'pre'}}>
			                	{this.state.name}
							</span>
						</DialogContentText>
					</DialogContent>
				</Dialog>
				<Typography variant="subtitle1" gutterBottom>
					{title}
				</Typography>
				<div className={classes.root}>
					{items.length > 0 ? (
						<List component="nav">
							{listItems}
						</List>
					) : (

						<div className={classes.empty}>
							Aucune données
						</div>
					)}
				</div>
			</div>
		);
	}
}

ListeActionLogs.propTypes = {
	classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(ListeActionLogs)