import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import AppsIcon from '@material-ui/icons/Apps';
import Drawer from '@material-ui/core/Drawer';
import NavItem from './NavItem'

const drawerWidth = 240;
const styles = theme => ({
	drawerPaper: {
		position: 'relative',
		width: drawerWidth,
	},
	toolbar: theme.mixins.toolbar,
	dashbord: {
		color: '#2F80ED',
	},
	primary: {
		color: '#2F80ED',
	}

});

/**
 * return menu DrawerContainer
 * @param props
 * @returns {XML}
 * @constructor
 */
function DrawerContainer(props) {
	const {classes} = props;

	return (

		<Drawer
			variant="permanent"
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor='left'
		>
			<div className={classes.toolbar}/>
			<Divider/>
			<List component="nav">
				<NavItem title="Dashboard" to="/" exact>
					<AppsIcon/>
				</NavItem>
				<NavItem title="Tâches" to="/Task">
					<FolderIcon/>
				</NavItem>
				<NavItem title="Action" to="/Action">
					<FileIcon/>
				</NavItem>
				{/*<NavItem title="Historique" to="/Historique">*/}
				{/*	<ScheduleIcon/>*/}
				{/*</NavItem>*/}
			</List>
		</Drawer>

	);
}

DrawerContainer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DrawerContainer);
