import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'

const styles = theme => ({
	button: {
		margin: theme.spacing(1),
		background: '#0E9755',
		color: 'white'
	},
})

/**
 * return class Button
 * @param props {string} titleButton
 * @returns {XML}
 * @constructor
 */
function ButtonTitle(props) {
	const {classes, titleButton } = props;
	return (
		<Button variant="contained" aria-label="Add" to="#" className={classes.button}
		        component={Link}>
			{titleButton}
		</Button>
	)
}

ButtonTitle.propTypes = {
	classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(ButtonTitle)
