import React from 'react'
import Drawer from './DrawerContainer.js'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
	appFrame: {
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},

	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: '#eee',
		padding: theme.spacing(3),
	},
})

function Layout(props) {
	const {classes, children,isPublic} = props;
	return (
		<div className={classes.appFrame}>
			{isPublic ? '' : <Drawer/>}
			<main className={classes.content}>
				<div className={classes.toolbar}/>
				{children}
			</main>
		</div>

	)
}

export default withStyles(styles)(Layout)