const defaultState = {
    "scheduled_tasks": [],
    "manual_tasks": []
}
const tasks = (state = defaultState , action) => {
	switch (action.type) {
		case 'RECEIVE_TASKS':
			return action.payload

		default:
			return state
	}
}
export default tasks