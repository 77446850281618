import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Task from '../index'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {connect} from 'react-redux'
import ListItemSwicht from './ListItemSwicht'
import SnackBar from './SnackBar'
import {loadTaskDetails, loadListAction, toggleListAction,loadTaskLogsById }from '../../../actions/index'
import DialogAddAction from './DialogAddAction'
import SplitDateList from '../../Dashboard/splitDate.js'
import renderItem from './TaskDetailsItem.js'
import Dialog from "@material-ui/core/Dialog"
import PopupTaskLog from "../../../components/PopupTaskLog"

const styles = theme => ({
	root: {
		flexGrow: 1,
	},

	control: {
		padding: theme.spacing(2),
	},
	container: {
		display: 'flex',
	},
	button: {
		margin: theme.spacing(1),
		background: '#4285F4',
		color: 'white'
	},
	buttonAddAction: {
		margin: theme.spacing(1),
		background: '#0E9755',
		color: 'white',
	},
	program: {
		width: '100%',
		height: '80px',
		backgroundColor: theme.palette.background.paper,
		fontFamily: 'Roboto',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center'
	},
	paper: {
		position: 'absolute',
		width: theme.spacing(50),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	itemRight: {
		display: 'flex',
		justifyContent: 'flex-end'
	}

})

class TaskDetails extends React.Component {

	state = {
		checked: false,
		open: false,
		isOpen: false,
		openId: 0
	}

	handleChange = () => {
		this.setState(state => ({checked: !state.checked}));

	}

	handleChangeRadio = name => event => {
		this.setState({[name]: event.target.checked});
	};

	handleOpenAddAction = () => {
		this.props.OpenDialogAction()
		this.setState({open: true});
	};

	handleCloseAction = () => {
		this.setState({open: false});
	};

	handleClickOpen = (taskLogId, taskName) => {
		this.setState({openId: taskLogId, name: taskName});
	};

	handleClose = () => {
		this.setState({openId: 0, name: ''});
	};

	loadTaskLogsById = () => {
		if (this.state.id !== this.props.match.params.id) {
			this.setState(state => ({id: this.props.match.params.id}));
			this.props.loadTaskLogsById(this.props.match.params.id)
		}
	};

	loadDetails = () => {
		if (this.state.id !== this.props.match.params.id) {
			this.setState(state => ({id: this.props.match.params.id}));
			this.props.loadTaskDetails(this.props.match.params.id)
		}
	};

	render() {
		this.loadDetails()
		this.loadTaskLogsById()
		const {
			classes,
			checked,
			task,
			listAction,
			fullScreen,
			match,
			listetasklogs,
			isPending
		} = this.props

		const id = match.params.id

		return (
			<div className={classes.root}>
				<Grid container spacing={4}>
					<Task withoutContainer taskId={id}/>
					<Grid item xs={6}>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<Typography variant="subtitle1" gutterBottom>
									{task.name}
									<Switch checked={checked} onChange={this.handleChange}
									        aria-label="Collapse">Activé</Switch>
								</Typography>
							</Grid>
							<Grid item xs={6} className={classes.itemRight}>

								<SnackBar taskId={this.state.id}/>
							</Grid>
						</Grid>
						<Typography variant="subtitle1" gutterBottom className={classes.program}>
							Type de programmation :
						</Typography>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<Typography variant="subtitle1" gutterBottom>
									{task.actions.length} actions
								</Typography>
							</Grid>
							<Grid item xs={6} className={classes.itemRight}>
								<Button onClick={this.handleOpenAddAction} variant="contained" aria-label="Add"
								        className={classes.buttonAddAction}>
									Ajouter une action
								</Button>
								<DialogAddAction listActions={listAction} handleopen={this.handleCloseAction}
								                 fullScreen={fullScreen}/>
							</Grid>
						</Grid>
						<ListItemSwicht items={task.actions} idTask={this.state.id} refresh={(id) => this.props.loadTaskDetails(id)}/>
						<span>
							<Dialog onClose={this.handleClose} open={this.state.openId === 0 ? false:true }>
					<PopupTaskLog taskLog={this.state.openId} taskName={this.state.name}/>
				</Dialog>
				<SplitDateList emptyText={"Aucune donnée"} data={listetasklogs} isPending={isPending} renderItem={renderItem} onClick={this.handleClickOpen}/>
						</span>
					</Grid>
				</Grid>
			</div>

		)
	}
}

const mapStateToProps = state => ({
	task: state.taskDetailsReducer,
	listAction: state.listAction,
	listetasklogs: state.listTaskLogsDetailsbyIdReducer

})

const mapDispatchToProps = dispatch => ({
	loadTaskDetails: (id) => dispatch(loadTaskDetails(id)),
	loadListAction: () => dispatch(loadListAction()),
	OpenDialogAction: () => {
		dispatch(toggleListAction())
	},
	loadTaskLogsById: (id) => dispatch(loadTaskLogsById(id)),

})

TaskDetails.propTypes = {
	classes: PropTypes.object.isRequired,
}

TaskDetails = withStyles(styles)(TaskDetails)

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails)