import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import PopupTaskLog from '../../components/PopupTaskLog.js'
import SplitDateList from './splitDate.js'
import renderItem from './TaskFinishedItem.js'

const styles = theme => ({});
class TaskFinished extends React.Component {
	state = {
		openId: 0,
		name: ''
	};

	handleClickOpen = (taskLogId, taskName) => {
		this.setState({openId: taskLogId, name: taskName});
	};

	handleClose = () => {
		this.setState({openId: 0, name: ''});
	};

	render() {
		const {items,isPending} = this.props
		return (
			<div>
				<Dialog onClose={this.handleClose} open={this.state.openId === 0 ? false:true}>
					<PopupTaskLog taskLog={this.state.openId} taskName={this.state.name}/>
				</Dialog>
				<SplitDateList emptyText={"Aucune donnée"} data={items} isPending={isPending} renderItem={renderItem} onClick={this.handleClickOpen}/>
			</div>
		);
	}
}

TaskFinished.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskFinished);
