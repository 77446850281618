import { connect } from 'react-redux'
import { login, changeFormLogin } from './LoginActions'
import Login from './Login'

const mapStateToProps = state => {
    return {
        isLogged: state.user.isLogged,
        fields: state.login,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: event => {
            dispatch(changeFormLogin(event.target.id,event.target.value))
        },
        onSubmit: (email,password) => {
            dispatch(login({email:email,password:password}))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
