import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Redirect } from 'react-router'

const styles = theme => ({
	paper: theme.mixins.gutters({
		margin:'auto',
		padding: 25,
		marginTop: theme.spacing(3),
		width:'400px',
	}),
	container:{

	},
	right:{
		alignSelf:'flex-end'
	},
	appLogo: {
		width:'180px',
	},
	textField: {
		width :250,
	},
	button:{
		marginTop: theme.spacing(4),
	}
});

let Login = ({ fields,classes,isLogged,onChange,onSubmit }) => {

	if (isLogged) {
		return <Redirect to='/'/>;
	}
	return (

		<Paper className={classes.paper} elevation={4}>
			<Grid container direction='column' alignItems='center' justify='center'>
				<Grid item >
					<Typography variant="subtitle1" gutterBottom>
						Identification
					</Typography>
				</Grid>
				<Grid item>
					<TextField
						error = {fields.email.error}
						helperText={fields.email.helperText}
						id="email"
						label="Email"
						className={classes.textField}
						value={fields.email.value}
						onChange={onChange}
						margin="normal"
					/>
				</Grid>
				<Grid item>
					<TextField
						error = {fields.password.error}
						helperText={fields.password.helperText}
						id="password"
						label="Mot de passe"
						className={classes.textField}
						value={fields.password.value}
						type="password"
						autoComplete="current-password"
						onChange={onChange}
						margin="normal"
					/>
				</Grid>
				<Grid item>
					<Button raised="true" color="primary" className={classes.button} onClick={(e) => onSubmit(fields.email.value,fields.password.value)}>
						connexion
					</Button>
				</Grid>
			</Grid>
		</Paper>
	)
}
export default withStyles(styles)(Login);
