import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import WarningIcon from '@material-ui/icons/Warning';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux'
import {launchJob} from '../../../actions/index'

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles1 = theme => ({

	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});

function MySnackbarContent(props) {
	const {classes, className, message, onClose, variant, ...other} = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
					{message}
        </span>
			}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					className={classes.close}
					onClick={onClose}
				>
					<CloseIcon className={classes.icon}/>
				</IconButton>,
			]}
			{...other}
		/>
	);
}

MySnackbarContent.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['success', 'error']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
	button: {
		margin: theme.spacing(1),
		background: '#4285F4',
		color: 'white'
	},
});

class Snackbars extends React.Component {

	constructor() {
		super();
		this.state = {
			openSnackbars: false,
			status: "error"
		};
	}

	handleClick = (id) => {

		this.setState(state => ({
			openSnackbars: true
		}));

		this.props.launchJob(id)
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({openSnackbars: false});
	};

	render() {
		const {classes, taskId, launch} = this.props;
		return (
			<div>
				<Button variant="contained" aria-label="Add" className={classes.button}
				        onClick={() => this.handleClick(taskId)}>
					DEMARRER
				</Button>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.openSnackbars}
					autoHideDuration={3000}
					onClose={this.handleClose}
				>
					{this.props.launch === 'succes' ? (
						<MySnackbarContentWrapper
							onClose={this.handleClose}

							variant='succes'
							message={launch.message}
						/>
					) : (

						<MySnackbarContentWrapper
							onClose={this.handleClose}

							variant='error'
							message={launch.message}
						/>
					)}

				</Snackbar>
			</div>
		);
	}
}

Snackbars.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	launch: state.launchJobReducer,

})

const mapDispatchToProps = dispatch => ({
	launchJob: (id) => dispatch(launchJob(id))

})
Snackbars = withStyles(styles2)(Snackbars)

export default connect(mapStateToProps, mapDispatchToProps)(Snackbars)