import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CachedIcon from '@material-ui/icons/Cached';
import DataList from '../../components/DataList.js'

const styles = theme => ({
	success: {
		color: '#219653',
	},
	warning: {
		color: '#F2994A',
	}
});

function TaskInProgress(props) {

	const {classes, title, items,isPending} = props;

	const listItems = items.map((item) =>
		<ListItem key={item.id} button>
			<ListItemIcon>
				{item.state === 'RUNNING' ? (
					<CachedIcon className='rotate'/>
				) : (
					<ScheduleIcon className={classes.warning}/>
				)}
			</ListItemIcon>
			<ListItemText primary={item.task.name}/>
		</ListItem>
	);
	return (
        <DataList title={title} emptyText={"C'est le calme plat !"} nbItems={items.length } isPending={isPending}>
            {listItems}
        </DataList>
	);
}

TaskInProgress.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskInProgress);
