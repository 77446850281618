const token = window && window.localStorage?localStorage.getItem('token'):null
const initialState = {
    name: null,
    email : null,
    isLogged: token ? true : false,
    initial:null,
    color:'#ccc',
    loading : false
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return {...state, loading:action.loading}
        case 'RECEIVE_LOGIN':
            return {...state, loading:action.loading, isLogged:action.payload.isLogged}
        case 'RECEIVE_ME':
            return {...state, ...action.payload}
        case 'AUTH_LOGOUT':
            return {...initialState,token:null}
        default:
            return state
    }
}

export default user