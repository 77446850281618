import API from 'redux-api-manager';

let api = new API({url: process.env.REACT_APP_API_URL})

export function loadTaskInProgress() {
    return api.endpoint('taskLogs/inProgress').read()
}

export function loadTaskFinished() {
    return api.endpoint('taskLogs/completed').read()
}

export function loadTaskPlaned() {
    return api.endpoint('tasks/scheduled').read()
}

export function loadTaskLogs(id) {
    return api.endpoint('tasklogs').read(id)
}

export function loadTaskLogsById(id) {
    let ep = api.endpoint('logs')
    ep.nested('tasks', id)
    return ep.read()
}

export function loadHistoryThisWeek(id) {
    return api.endpoint('historyThisWeek').read()
}

export function loadHistoryLastWeek(id) {
    return api.endpoint('historyLastWeek').read()
}

export function loadTasks() {
    return api.endpoint('tasks').read()
}

export function loadTaskDetails(id) {
    let ep = api.endpoint('tasks')
    ep.renameAction('TASK_DETAILS')
    return ep.read(id)
}

export function loadTaskLogsDetailsCategory(id) {
    let ep = api.endpoint('taskLogsCategory')
    ep.renameAction('TASK_LOGS_DETAILS')
    return ep.read(id)
}

export function launchJob(id) {
    let ep = api.endpoint('start')
    ep.nested('tasks', id)
    return ep.read()
}

export function toggleSatutAction(id) {
    let ep = api.endpoint('taskAction')
    return ep.update({id: id})
}

export function loadListAction() {
    return api.endpoint('actions').read()
}

export function loadDetailsAction(id) {
    let ep = api.endpoint('actions')
    ep.renameAction('DETAIL_ACTIONS')
    return ep.read(id)
}

export function loadActionLogs(id) {
    let ep = api.endpoint('logs')
    ep.nested('actions', id)
    return ep.read()
}

export function loadLastExec() {
    return api.endpoint('lastExec').read()
}

export function toggleAction(id) {
    return {
        type: 'TOGGLE_ACTION',
        id: id
    }
}

export function resetListAction() {
    return {
        type: 'RESET_LISTACTION',
    }
}

export function toggleListAction() {
    return {
        type: 'TOGGLE_LISTACTION',
    }
}

export function loadDetailsExecution(id) {
    return api.endpoint('detailsExecution').read()
}

export function toggleDialogDetailsExecution() {
    return {
        type: 'TOGGLE_DETAILS_EXECUTION'
    }
}

export function loadTasklogsStories() {
    let ep = api.endpoint('tasklogs')
    ep.renameAction('TASK_LOGS_STORIES')
    return ep.read()
}
