import React from 'react'
import Typography from '@material-ui/core/Typography'
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			fontSize: '24px',
			fontWeight: '500',
			fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
		}
	}),
);

export default function Title(props) {
	const classes = useStyles();
	const {children} = props;
	return (
		<Typography variant="body1" className={classes.title}>{children}</Typography>
	)
}
