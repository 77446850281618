import React from 'react';

import Layout from '../components/Layout';
import {Route} from 'react-router-dom'

const PublicRoute = ({component: Component, ...rest}) => (
	<Route {...rest} render={props => (
		<Layout isPublic>
			<Component {...props}/>
		</Layout>
	)}/>
)

export default PublicRoute;
