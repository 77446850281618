import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DataList from '../../components/DataList.js'
import {Link} from 'react-router-dom'

const styles = theme => ({
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	}
});

function TaskPlanned(props) {
	const {classes, title, items,isPending} = props;
	const listItems = items.map((item) =>
		<ListItem key={item.id} button component={Link} to={'Task/' + item.id}>

			<ListItemText primary={item.name}/>
			<ListItemSecondaryAction className={classes.secondary}>
				{item.shedule}
			</ListItemSecondaryAction>

		</ListItem>
	);

	return (
        <DataList title={title} emptyText={"RAS"} nbItems={items.length } isPending={isPending}>
            {listItems}
        </DataList>
	);
}

TaskPlanned.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskPlanned);
