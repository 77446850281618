import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TimerIcon from '@material-ui/icons/Timer'

const styles = theme => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto',
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	error: {
		color: '#EB5757'
	},
	flexContainer: {
		listStyle: 'none',
		display: 'flex',
		justifyContent: 'stretch',
	},

	duration: {
		textAlign: 'left',
		marginLeft: '-86px'
	}
});

class TaskDetailsItem extends React.Component {
	render() {
		const {classes, item, onClick} = this.props
		let itemClass = item.state === 'ERROR' ? {root: classes.error }: {}
		return (
			<ListItem key={item.id} button onClick={e => onClick(item.id, item.task.name)}
			          className={classes.flexContainer}>
				<ListItemText primary={item.started_at} classes={itemClass}/>
				<ListItemIcon classes={itemClass} style={{textAlign: 'right'}}>
					<TimerIcon />
				</ListItemIcon>
				<ListItemText inset primary={item.duration + "s"} classes={itemClass}
				              className={classes.duration}/>
			</ListItem>
		)
	}
}


export default withStyles(styles)(TaskDetailsItem);
