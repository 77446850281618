import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import {connect} from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {loadTaskLogs} from '../actions/index'
import TimerIcon from '@material-ui/icons/Timer'
import Dialog from '@material-ui/core/Dialog'
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List';

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	empty: {
		height: '150px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	},
	error: {
		color: '#EB5757'
	},
	flexContainer: {
		listStyle: 'none',
		display: 'flex',
		marginRight: '-16px',
		fontSize: '14px',
		fontFamily: 'Roboto',
		color:'black'
	},

	timer:{
		textAlign: 'left',
		marginLeft: '50px'
	},
	duration:{
		marginLeft: '-56px',
		textAlignLast:'end'
	}

});

class popupTaskLog extends React.Component {

	componentDidMount() {
		this.props.load(this.props.taskLog)
	}

	state = {
		openIdAction: 0,
	};

	handleClickOpen = (actionId, log, nameAction) => {
		this.setState({
			openIdAction: actionId,
			log: log,
			nameAction: nameAction
		});
	};

	handleClose = () => {
		this.setState({openIdAction: 0, log: '', nameAction: ''});
	};

	render() {
		const {classes, taskName, details} = this.props
		let listDetailsAction = details.actionsLog.map((item) => {
				let itemClass = item.state === 'ERROR' ? {root: classes.error }: {}
				return   <List key={item.id.toString()}>
			<ListItem  button className={classes.flexContainer}
				                 onClick={e => this.handleClickOpen(item.id, item.log, item.action.name)}>
					<ListItemText inset primary={item.action.name} classes={itemClass} />
					<ListItemIcon classes={itemClass} >
						<TimerIcon className={classes.timer}/>
					</ListItemIcon>
					<ListItemText inset primary={item.duration +"s"} classes={itemClass}  className={classes.duration}  />
				</ListItem>
			</List>
			}
		)

		return (
			<div>
				<DialogTitle id="alert-dialog-title">{taskName}
				</DialogTitle>
				<DialogContent>
					<ListItem  button  className={classes.flexContainer}>
						<ListItemText inset primary={details.started_at} />
						<ListItemIcon>
							<TimerIcon  className={classes.icone} />
							<ListItemText inset primary={details.duration +"s"} className={classes.duration} />
						</ListItemIcon>

					</ListItem>
				</DialogContent>
				<DialogTitle id="alert-dialog-title">{details.actionsLog.length} Actions
				</DialogTitle>
				<Divider/>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" component={'span'}  >
						{listDetailsAction}
					</DialogContentText>
				</DialogContent>
				<Dialog
					open={ this.state.openIdAction === 0 ? false:true}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title"> {taskName + '/' + this.state.nameAction}</DialogTitle>
					<Divider/>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
						  <span style={{whiteSpace: 'pre'}}>
			                {this.state.log}
							</span>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</div>

		)
	}
}

const mapStateToProps = state => ({
	details: state.listTaskActionReducer
})

const mapDispatchToProps = dispatch => ({
	load: (id) => dispatch(loadTaskLogs(id)),
})

popupTaskLog = withStyles(styles)(popupTaskLog)
export default connect(mapStateToProps, mapDispatchToProps)(popupTaskLog)