import React from 'react'
import {connect} from 'react-redux'
import ListItemLink from '../../components/ListItemLink'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import ButtonTitle from '../../components/ButtonTitle'
import {loadListAction} from '../../actions/index'

class Action extends React.Component {

	componentDidMount() {
		this.props.loadListAction()
	}

	render() {
		const {
			listeAction,
			withoutContainer
		} = this.props

		let categoryAction = listeAction.map((item) =>
			<div>
				<ListItemLink title={item.name} url="/Action/" items={item.action} key={item.id}/>
				<Divider/>
			</div>
		)

		let actionGrid = (
			<Grid item xs={4}>
				<ButtonTitle titleButton="Ajouter une catégorie"/>
				{categoryAction}
			</Grid>
		)

		if (!withoutContainer) {
			actionGrid = (<Grid container spacing={0}>{actionGrid}</Grid>)
		}

		return actionGrid
	}
}


const mapStateToProps = state => ({
	listeAction: state.listeActionReducer
})

const mapDispatchToProps = dispatch => ({
	loadListAction: () => dispatch(loadListAction()),

})
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Action)




