let defaultState = {
	"status": "error",
	"message": "There was a problem updating Action Status, please try again later."
}
const statutActionReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_TASKACTION_UPDATE':
			return action.payload
		default:
			return state
	}
}
export default statutActionReducer