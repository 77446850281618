import React from 'react';
import {connect} from 'react-redux'
import TaskInProgress from './TaskInProgress.js'
import TaskPlaned from './TaskPlanned.js'
import TaskFinished from './TaskFinished.js'
import Grid from '@material-ui/core/Grid'
import {loadTaskFinished,loadTaskInProgress, loadTaskPlaned} from '../../actions/index'

class Dashboard extends React.Component {

	componentDidMount() {
		this.props.loadTaskFinished()
		this.props.loadTaskInProgress()
		this.props.loadTaskPlanned()
	}

	render() {

		const {
			taskInProgress,
			taskPlaned,
			taskFinished
		} = this.props

		return (

			<Grid container spacing={4}>
				<Grid item md={5} xs={12}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<TaskInProgress title="Tâches en cours" items={taskInProgress.items} isPending={taskInProgress.isPending}/>
						</Grid>
						<Grid item xs={12}>
							<TaskPlaned title="Tâches planifiées" items={taskPlaned.items} isPending={taskPlaned.isPending}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={7} xs={12}>
					<TaskFinished title="Tâches terminées" items={taskFinished.items} isPending={taskFinished.isPending}/>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	taskFinished: state.taskFinishedReducer,
	taskPlaned: state.taskPlannedReducer,
	taskInProgress: state.taskInProgressReducer

})

const mapDispatchToProps = dispatch => ({
	loadTaskPlanned: () => dispatch(loadTaskPlaned()),
	loadTaskInProgress: () => dispatch(loadTaskInProgress()),
	loadTaskFinished: () => dispatch(loadTaskFinished())
})
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Dashboard)