let defaultState = {
	"status": "error",
	"message": "Job id:default not found!"
}

const launchJobReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_JOBS_START':
			return action.payload
		default:
			return state
	}
}
export default launchJobReducer