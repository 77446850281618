import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import PopupCheckbox from './PopupCheckbox'
import DialogContent from '@material-ui/core/DialogContent'
import {resetListAction, toggleListAction} from '../../../actions/index'
import {connect} from 'react-redux'

function DialogAddAction(props) {
	const {listActions, isOpen, fullScreen, resetListAction, closeAction} = props
	const actions = listActions.cats.map((list) =>
		<div key={list.title.toString()}>
			<Typography variant="subtitle1" gutterBottom  >
				{list.title}
			</Typography>

			<PopupCheckbox actions={listActions.actions} idsActions={list.idsAction}/>

		</div>
	)
	return (

		<Dialog
			fullScreen={fullScreen}
			open={isOpen}
			onClose={closeAction}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogContent>
				{actions}
			</DialogContent>
			<DialogActions>
				<Button onClick={resetListAction} color="primary">
					Annuler
				</Button>
				<Button onClick={closeAction} color="primary" autoFocus>
					Ajouter
				</Button>
			</DialogActions>
		</Dialog>
	)
}

DialogAddAction.propTypes = {

}

const mapStateToProps = state => ({
	isOpen: state.listAction.isOpen
})

const mapDispatchToProps = dispatch => ({
	resetListAction: () => {
		dispatch(toggleListAction())
		dispatch(resetListAction())

	},
	closeAction: () => {
		dispatch(toggleListAction())
	}
})


export default connect(mapStateToProps, mapDispatchToProps)(DialogAddAction)