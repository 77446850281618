let defaultState = {
	"id":0,
	"state":"loading",
	"started_at":"",
	"duration":"",
	actionsLog:[]
}

const listTaskActionReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_TASKLOGS':
			return action.payload

		default:
			return state
	}
}
export default listTaskActionReducer