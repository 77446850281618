import React from 'react'

const NotFound = () => {
	return (
		<div>
			<h1>you are a cheater</h1>
			<h2>NotFound</h2>
		</div>
	)
}

export default NotFound