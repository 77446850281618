import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import {Link, Route} from "react-router-dom"

const styles = theme => ({
	selected: {
		color: '#2F80ED',
	}
});

function NavItem(props) {
	const {classes, title, children, exact, to} = props;

	let classIcon = {root: classes.selected}
	let classText = {primary: classes.selected}

	return (
		<Route
			path={to}
			exact={exact}
			children={({match}) => (
				<ListItem button component={Link} to={to}>
					<ListItemIcon classes={match ? classIcon : {}}>
						{children}
					</ListItemIcon>
					<ListItemText classes={match ? classText : {}} primary={title}/>
				</ListItem>
			)}
		/>
	);
}

NavItem.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavItem);
