let defaultState =
	[
		{
			"id": 242,
			"started_at": "2019-05-14 18:11:32",
			"state": "ERROR",
			"duration": "a few seconds",
			"task": {
				"id": 1,
				"name": "Imports fichiers"
			}
		},


	]
const listTaskLogsDetailsbyIdReducer = (state = defaultState, action) => {
	switch (action.type) {
		case 'RECEIVE_TASKS_LOGS':
			return action.payload
		default:
			return state
	}
}

export default listTaskLogsDetailsbyIdReducer