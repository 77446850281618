import React from 'react';
import { connect } from 'react-redux'
import Layout from '../components/Layout';
import {Route, Redirect , withRouter} from 'react-router-dom'

const PrivateRoute = ({component: Component,isLogged, ...rest}) => (
	<Route {...rest} render={props => (
        isLogged ? (
		<Layout>
			<Component {...props}/>
		</Layout>
        ) : (
            <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }}/>
        )
	)}/>
)

const mapStateToProps = state => {
    return {
        isLogged: state.user.isLogged
    }
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
