import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TimerIcon from '@material-ui/icons/Timer';

const styles = theme => ({
	secondary: {
		color: '#828282',
		paddingRight: '10px',
		fontSize: '14px',
		fontFamily: 'Roboto'
	},
	error: {
		color: '#EB5757'
	},
	flexContainer: {
		listStyle: 'none',
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'flex-start'

	},
	flexItem: {
		padding: '5px',
		width: '200px',
		textAlign: 'center',
	},

	timer:{
		textAlign: 'left',
		marginLeft: '-86px'
	}
});
class TaskFinishedItem extends React.Component {
	render() {
		const {classes,item, onClick} = this.props
		let itemClass = item.state === 'ERROR' ? {root: classes.error }: {}
		return (
			<ListItem key={item.id.toString()} button onClick={e => onClick(item.id, item.task.name)}
			          className={classes.flexContainer}>
				<ListItemText primary={item.task.name} classes={itemClass} className={classes.flexItem}/>
				<ListItemIcon classes={itemClass} style={{textAlign: 'right'}}>
					<TimerIcon />
				</ListItemIcon>
				<ListItemText inset primary={item.duration + "s"} classes={itemClass}  className={classes.timer}/>
				<ListItemText inset primary={item.started_at} classes={itemClass} />
			</ListItem>
		)
	}
}
export default withStyles(styles)(TaskFinishedItem);
