import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Action from '../index'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import ListeActionLogs from './ListeActionLogs'

import {loadDetailsAction,loadLastExec,loadActionLogs} from '../../../actions/index'

const styles = theme => ({
	root: {
		flexGrow: 1,
		display: 'flex'

	},

	size: {
		width: 40,
		height: 40,
	},
	sizeIcon: {
		fontSize: 20,
	},

	formControl: {
		margin: theme.spacing(3),
	},
	group: {
		margin: `${theme.spacing(1)}px 0`,
	},

	container: {
		display: 'flex',
	},
	button: {
		margin: theme.spacing(1),
		background: '#4285F4',
		taskType: 'white'
	},

	paper: {
		position: 'absolute',
		width: theme.spacing(50),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	itemRight: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	control: {
		padding: theme.spacing(2),
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},

})

class ActionDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {value: ''}
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(event) {
		this.setState({value: event.target.value});
	}

	loadDetails = () => {
		if (this.state.id !== this.props.match.params.id) {
			this.setState(state => ({
				id: this.props.match.params.id,
				value: ''
			}))
			this.props.loadDetailsAction(this.props.match.params.id)
		}
	}
	loadActionLogsById = () => {
		if (this.state.id !== this.props.match.params.id) {
			this.setState(state => ({id: this.props.match.params.id}));
			this.props.loadActionLogs(this.props.match.params.id)
		}
	};


	render() {
		this.loadDetails()
		this.loadActionLogsById()
		const {
			classes,
			listAction,listeActionLogs

		} = this.props
		return (
			<div className={classes.root}>
				<Grid container spacing={6}>
					<Action withoutContainer/>
					<Grid item xs={6}>
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<Typography variant="subtitle1" gutterBottom>
									{listAction.name}
								</Typography>
							</Grid>
							<Grid item xs={6} className={classes.itemRight}>
								<Button variant="contained" aria-label="Add" className={classes.button}>
									Demarer
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Paper className={classes.control}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Type de tâche</FormLabel>
										<RadioGroup aria-label="position" name="position" value={this.state.value}  onChange={this.handleChange}row>
											<FormControlLabel
												value={listAction.actionfile}
												control={<Radio color="primary" />}
												label="Fichier Javascript"
												labelPlacement="end"
											/>
											<FormControlLabel
												value={listAction.sql !== null ? listAction.sql: '' }
												control={<Radio color="primary" />}
												label="Code Sql"
												labelPlacement="end"
											/>
										</RadioGroup>
									</FormControl>


									<Grid item xs={12}>
										<TextField
											id="full-width"
											InputLabelProps={{
												shrink: true,
											}}
											placeholder=''
											value={this.state.value}
											multiline
											fullWidth
											margin="normal"
										/>
									</Grid>
								</Paper>
								<ListeActionLogs title="Historiques des executions" items={listeActionLogs}  />
								</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>

		);
	}
}

const mapStateToProps = state => ({
	listAction: state.actionsDetailsReducer,
	listeActionLogs: state.actionLogsReducer

})

const mapDispatchToProps = dispatch => ({
	loadDetailsAction: (id) => dispatch(loadDetailsAction(id)),
	loadLastExec: () => dispatch(loadLastExec()),
	loadActionLogs: (id) => dispatch(loadActionLogs(id)),

})

ActionDetails.propTypes = {
	classes: PropTypes.object.isRequired,
}

ActionDetails = withStyles(styles)(ActionDetails)
export default connect(mapStateToProps, mapDispatchToProps)(ActionDetails)