import React from 'react'
import moment from "moment"
import DataList from "../../components/DataList";

class SplitDate extends React.Component {

	render() {
		const {data,renderItem: RenderItem,isPending,emptyText,onClick} = this.props
		let dataByWeek = []
		data.map((item) => {
			let numberOfWeek = moment(item.started_at).isoWeek()

			if((numberOfWeek) ===  (moment().isoWeek())){
				item.weekTitle = 'Cette semaine ( Semaine ' + numberOfWeek + ' )'
			}else{
				item.weekTitle = 'Semaine ' + numberOfWeek
			}
			if (!dataByWeek[numberOfWeek]){
				dataByWeek[numberOfWeek]=[]
			}
			return dataByWeek[numberOfWeek].push(item)
		})

		let list = dataByWeek.reverse().map((weekData) => (
					<DataList key={weekData[0].id}  title={weekData[0].weekTitle} emptyText={emptyText} nbItems={data.length } isPending={isPending}>
						{weekData.map((item) => <RenderItem item={item} key ={item.id} onClick={onClick} />)}
					</DataList>
				)
			)
		return list
	}
}
export default (SplitDate);