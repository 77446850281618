import API from 'redux-api-manager';
let api = new API({url: process.env.REACT_APP_API_URL})

export function changeFormLogin (id,value){
    return {
        type: 'CHANGE_FORM_LOGIN',
        key: id,
        value: value
    }
}
export function login(credential) {
   return api.endpoint('login').login(credential)
}
export const logout = () => {
    localStorage.removeItem('token');
    return {
        type: 'AUTH_LOGOUT'
    }
}