const defaultState = {
	isOpen: false,
	cats: [
		{
			title: 'intégration',
			idsAction: [1, 2, 3]
		},
		{
			title: 'Calculs',
			idsAction: [4, 5, 6]
		},
		{
			title: 'sql',
			idsAction: [7]
		}
	],
	actions: {
		1: {id: 1, title: 'Article', checked: false},
		2: {id: 2, title: 'Ventes', checked: true},
		3: {id: 3, title: 'Nomenclature', checked: true},
		4: {id: 4, title: 'Forecast', checked: false},
		5: {id: 5, title: 'BOM', checked: false},
		6: {id: 6, title: 'Nomenclature', checked: false},
		7: {id: 7, title: 'cleaning', checked: false},
	}
}

const listAction = (state = defaultState, action) => {
	switch (action.type) {
		case 'TASK':
			if (action.data !== undefined) {
				return action.data
			}
			return state

		case 'TOGGLE_ACTION':
			let newState = {
				...state,
				actions: {
					...state.actions,
					[action.id]: {
						...state.actions[action.id],
						checked: !state.actions[action.id].checked
					}
				}
			}
			return newState
		case  'RESET_TASK_DETAILS':
			return defaultState

		case 'TOGGLE_LISTACTION':
			return   {
				...state,
				isOpen: !state.isOpen
			}

		default:
			return state
	}
}
export default listAction